import jsonldDS from '@itbpbg/lms-components/src/utils/json-ld-ds';
import jsonldFA from '@itbpbg/lms-components/src/utils/json-ld-fa';

function dateIsoString(formatDate) {
  const date = formatDate ? new Date(`${formatDate}`.replace(/-/g, '/')) : new Date();

  if (date.toString() === 'Invalid Date') {
    return '';
  }

  return date.toISOString();
}

export default ({ $config, route }, inject) => {
  const defaults = $config.platform === 'ds' ? jsonldDS : jsonldFA;

  inject('linkedDataLoad', (targets) => {
    let graph = [];

    if (targets.length) {
      graph = targets.map(({ key, values }) => {
        let obj = { ...defaults[key] };

        if (key === 'WebPage') {
          obj = {
            ...obj,
            url: `${$config.siteUrl}${route.fullPath}`,
            name: values.metaTitle,
            description: values.metaDescription,
            datePublished: dateIsoString(values.created),
            dateModified: dateIsoString(values.updated),
            potentialAction: [
              { ...defaults.PotentialActions.consume.read, target: values.fullPath },
              ...(values.potentialAction ? [...values.potentialAction] : []),
            ],
          };
        } else { // values contains only data for json-ld
          obj = { ...defaults[key], ...values };
        }

        return obj;
      });
    }

    return {
      vmid: 'jsonld',
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@graph': graph,
      },
    };
  });

  inject('linkedDataDefault', (key) => defaults[key] || {});
};
