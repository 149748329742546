import Vue from 'vue';
import { defer } from 'lodash-es';

import swipeEvents from '@itbpbg/lms-components/src/plugins/swipe-events';
import eventPassive from '@itbpbg/lms-components/src/plugins/event-passive';

import { validateUrl } from '../utils/helper';

Vue.use(swipeEvents);

Vue.prototype.$copy = function copy(value) {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

Vue.prototype.$watchUntilTruly = function watchUntilTruly(getter) {
  return new Promise((resolve) => {
    const unwatch = this.$watch(
      getter,
      (value) => {
        if (!value) return;
        resolve();
        defer(() => unwatch());
      },
      { immediate: true },
    );
  });
};

if (process.client) {
  window.redirectAfterPurchase = function redirectAfterPurchase(orderReference) {
    if (orderReference) {
      fastspring.builder.reset();
      if (window.$nuxt.$store.getters['auth/user'].id) {
        window.location.href = `${this.$nuxt.$config.lmsUrl}/thank-you/?subscribe=true`;
      } else {
        window.location.href = `${this.$nuxt.$config.siteUrl}/thank-you/?subscribe=true`;
      }
    }
  };
}

Vue.prototype.$redirectAfterLogin = function redirectAfterLogin(toURL) {
  const { redirectUrl } = this.$route.query;

  if (redirectUrl) {
    const url = validateUrl(redirectUrl);
    if (url && url.origin === this.$config.lmsUrl) {
      window.location.href = decodeURI(redirectUrl);
      return;
    }
  }

  const adminPermissions = this.$store.getters['auth/perms'] && (this.$store.getters['auth/perms'].some((p) => p.includes('admin-legacy.')) || this.$store.getters['auth/user'].role === 'admin');
  if (adminPermissions) {
    window.location.href = `${this.$config.lmsUrl}/admin-legacy/`;
    return;
  }

  const businessPermission = this.$store.getters['auth/user'] && this.$store.getters['auth/user'].role === 'business-admin';
  if (businessPermission) {
    window.location.href = `${this.$config.lmsUrl}/business/`;
    return;
  }

  window.location.href = this.$config.lmsUrl + (toURL || '');
};

Vue.prototype.$delayExecution = function delayExecution(fn, threshhold, condition, scope) {
  let last;
  let deferTimer;
  return function f(...args) {
    const vm = scope || this;
    const now = +new Date();

    if (last && now < last + threshhold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(() => {
        last = now;
        if (!condition) {
          fn.apply(vm, args);
        }
      }, threshhold);
    } else {
      last = now;
      fn.apply(vm, args);
    }
  };
};

Vue.prototype.$pollValue = function pollValue(v) {
  return new Promise((resolve) => {
    const id = setInterval(() => {
      if (!v()) return;
      clearInterval(id);
      resolve();
    }, 300);
  });
};

Vue.prototype.$getAppliedFilters = function $getAppliedFilters(f) {
  return Object
    .entries(f)
    .reduce((p, [__k, __v]) => ({
      ...p,
      [__k]: Object
        .entries(__v.values)
        .filter(([, _v]) => _v.selected || _v.value)
        .map(([_k, _v]) => {
          if (__v.labelsAsValues) {
            return _v.text;
          }

          if (__v.type === 'text') {
            return _v.value;
          }

          return _k;
        }),
    }), {});
};

Vue.prototype.$getFilterParams = function $getFilterParams(f, _f) {
  return Object
    .entries(f)
    .map(([_k, _v]) => {
      // eslint-disable-next-line no-underscore-dangle
      if (_f[_k].type === 'multiple') {
        return _v.map((__v) => `filter[${_k}][]=${encodeURIComponent(__v)}`);
      }

      return _v.map((__v) => `filter[${_k}]=${encodeURIComponent(__v)}`);
    })
    .flat()
    .join('&');
};

Vue.prototype.$loadScript = async function loadScript(src, attributes = {}) {
  const scriptExists = document.querySelector(`script[src="${src}"]`);

  if (scriptExists) {
    return true;
  }

  const script = document.createElement('script');
  script.src = src;

  document.head.appendChild(script);

  // Add attributes to script tag
  Object.entries(attributes).forEach(([key, value]) => {
    script.setAttribute(key, value);
  });

  return new Promise((resolve) => {
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
  });
};

Vue.prototype.$validEmail = function validateEmail(email) {
  if (!email) {
    return false;
  }

  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email);
};

Vue.prototype.$validateUrl = function validateUrlByDomain(url, domain) {
  if (!url) {
    return false;
  }

  if (!domain) {
    // eslint-disable-next-line no-useless-escape
    return url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  }

  // eslint-disable-next-line no-useless-escape
  return new RegExp(`^((https?|http?):\/\/)?(www.)?${domain}.com\/[^ "]{3,}$`).test(url);
};

Vue.prototype.$sleep = function sleep(delay) {
  const start = new Date().getTime();
  while (new Date().getTime() < start + delay);
};

Vue.prototype.$parseCookies = function parseCookies(cookies = '') {
  return (cookies || '').split(';').reduce((p, c) => {
    const [k, v] = c.split('=');

    return { ...p, [k.trim()]: decodeURIComponent(v) };
  }, { });
};

function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }

  return '';
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } if (typeof document.msHidden !== 'undefined') {
    return 'msHidden';
  } if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }

  return '';
}

Vue.prototype.$getBrowserVisibilityProp = getBrowserVisibilityProp;
Vue.prototype.$getBrowserDocumentHiddenProp = getBrowserDocumentHiddenProp;
Vue.prototype.$eventPassive = eventPassive;
Vue.prototype.$webp = (src) => src.replace(/^(.+)\..+$/, '$1.webp');
Vue.prototype.$convertImageToWebpThumb = (thumbSize, directory, filename, extension) => {
  const ext = extension.split('.').pop();
  const isGif = ext === 'gif';

  // Fix for images with multiple dots in the name
  if (extension.split('.').length > 1) {
    return `${directory}${!isGif ? `thumb@${thumbSize}_` : ''}${filename}.${extension.split('.').splice(0, extension.split('.').length - 1).join('.')}.${!isGif ? 'webp' : ext}" data-ext="${ext}"`;
  }

  // Default case
  return `${directory}${!isGif ? `thumb@${thumbSize}_` : ''}${filename}.${!isGif ? 'webp' : ext}" data-ext="${ext}"`;
};
Vue.prototype.$convertDBDateToUTC = (date) => (date ? `${date.replace(' ', 'T')}Z` : '');
Vue.prototype.$passiveEventCheck = () => {
  let passiveSupported = false;

  try {
    const options = {
      get passive() {
        // This function will be called when the browser
        // attempts to access the passive property.
        passiveSupported = true;
        return false;
      },
    };

    window.addEventListener('test', null, options);
    window.removeEventListener('test', null, options);
  } catch (err) {
    passiveSupported = false;
  }

  if (passiveSupported) {
    return { passive: true };
  }

  return null;
};

export default async (context, inject) => {
  inject('globalHelper', {
    parseCookies: Vue.prototype.$parseCookies,
  });
};
