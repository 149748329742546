import { registerModal } from '../store/modals';

export default () => {
  registerModal({ name: 'default', component: async () => import('@itbpbg/lms-components/src/Modals/Default.vue') });
  registerModal({ name: 'confirm', component: async () => import('@itbpbg/lms-components/src/Modals/Confirm.vue') });
  registerModal({ name: 'share-question', component: async () => import('@itbpbg/lms-components/src/Modals/ShareQuestion') });
  registerModal({ name: 'lightBox', component: async () => import('@itbpbg/lms-components/src/Modals/LightBox') });
  registerModal({ name: 'video', component: async () => import('@itbpbg/lms-components/src/Modals/Video') });
  registerModal({ name: 'verified-certificate', component: async () => import('../components/Modals/VerifiedCertificate') });
  registerModal({ name: 'unverified-certificate', component: async () => import('../components/Modals/UnverifiedCertificate') });
  registerModal({ name: 'upcoming-course', component: async () => import('@itbpbg/lms-components/src/Modals/UpcomingCourse') });
  registerModal({ name: 'feedback', component: async () => import('@itbpbg/lms-components/src/Modals/Feedback') });
  registerModal({ name: 'contact-sales', component: async () => import('../components/Modals/ContactSales') });
  registerModal({ name: 'contact-support', component: async () => import('@itbpbg/lms-components/src/Modals/ContactSupport') });
  registerModal({ name: 'payment-taking-longer', component: async () => import('../components/Modals/PaymentTakingLonger') });
  registerModal({ name: 'share-results', component: async () => import('@itbpbg/lms-components/src/Modals/ShareResults') });
  registerModal({ name: 'login-or-signup', component: async () => import('../components/Modals/LoginOrSignup') });
  registerModal({ name: 'gallery', component: async () => import('@itbpbg/lms-components/src/Modals/Gallery') });
  registerModal({ name: 'lecture-preview', component: async () => import('@itbpbg/lms-components/src/Modals/LecturePreview') });
  registerModal({ name: 'courses-gate', component: async () => import('@itbpbg/lms-components/src/Modals/CoursesGate') });
  registerModal({ name: 'course-reviews', component: async () => import('@itbpbg/lms-components/src/Modals/CourseReviews') });
  registerModal({ name: 'builder-preview', component: async () => import('@itbpbg/lms-components/src/Modals/BuilderPreview') });
  registerModal({ name: 'mobile-preview', component: async () => import('@itbpbg/lms-components/src/Modals/MobilePreview') });
  registerModal({ name: 'laptop-preview', component: async () => import('@itbpbg/lms-components/src/Modals/LaptopPreview') });
  registerModal({ name: 'email-subscribed', component: async () => import('../components/Modals/EmailSubscribed') });
};
